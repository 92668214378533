import Vue from 'vue';
import {post} from '@/services/api';
import {creditOrderUpdate, creditOrderView} from '../../../services/endpoint.json';

export default Vue.extend({
  name: 'credit-history',
  data: () => ({
    message: null,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let {data} = await post(creditOrderView, {id: this.$route.params.id}, true);
      this.message = data.message
    },
    async update() {
      let {data} = await post(creditOrderUpdate, this.message, true);
      if (Number(data.statusCode) === Number(200)) {
        this.message = data.message
      }
    }
  },
});

